<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.recharge_record") }}
    </template>
    <template v-slot:contentBody="wrap">
      <div>
        <!-- <a-tabs type="card" v-model:activeKey="activeTabKey">
          <a-tab-pane :key="tabList[0].key" :tab="tabList[0].value"> -->
            <a-button
              type="primary"
              :loading="tableData.loading"
              @click="handleShowModal"
              >{{ $t("common.top_up") }}</a-button
            >
            <a-table
              :columns="columns"
              size="small"
              :data-source="tableData.tableList"
              :pagination="false"
              :scroll="{ x: true, y: wrap.contentHeight - 230 }"
              :rowKey="
                (record, index) => {
                  return index;
                }
              "
              :loading="tableData.loading"
            >
              <template v-slot:creationTime="{ record }">{{
                $filters.utcToCurrentTime(record.creationTime)
              }}</template>
              <template v-slot:rechargeAmount="{ record }">{{ $filters.formatCurrencyAmount(record.toSymbol, record.rechargeAmount, false) }}</template>
              <template v-slot:rechargeType="{ record }">{{ $t($enumLangkey('rechargeType', record.rechargeType)) }}</template>
              <template v-slot:payAmount="{ record }">{{ $filters.formatCurrencyAmount(record.fromSymbol, record.payAmount, false) }}</template>
              <template v-slot:status="{ record }">
                <span v-if="record.status === rechargeStatusEnum.toBeConfirmed">
                  <a-tag color="#faad14">{{ $t($enumLangkey('rechargeStatus', record.status)) }}</a-tag>
                </span>
                <span v-else :class="getTextColor(record.status)">{{ $t($enumLangkey('rechargeStatus', record.status)) }}</span>
              </template>
              <template #remark="{ record }">
                <span v-if="record.status === rechargeStatusEnum.fail">
                  {{ $t($enumLangkey('rejectInAccount', record.recordedFailureRemark)) }}
                </span>
                <template v-else>
                  <span v-if="record.remark">{{ record.remark }}</span>
                  <span v-else>-</span>
                </template>
              </template>
              <template #operation="{ record }">
                <a-dropdown v-if="record.status !== rechargeStatusEnum.fail && record.status !== rechargeStatusEnum.canceled" >
                  <template #overlay>
                    <a-menu @click="handleMenuClick">
                      <a-menu-item key="1" :record="record" v-if="record.status === rechargeStatusEnum.waitSubmit">{{ $t('common.submit') }}</a-menu-item>
                      <a-menu-item key="2" :record="record" >{{ $t('common.print') }}</a-menu-item>
                      <a-menu-item key="3" :record="record" v-if="record.status === rechargeStatusEnum.waitSubmit">{{ $t('common.cancel') }}</a-menu-item>
                    </a-menu>
                  </template>
                  <a-button :loading="record.loading">
                    {{ $t('common.operation') }}
                    <DownOutlined />
                  </a-button>
                </a-dropdown>
              </template>
              <template #title>
                <span style="font-weight: bold">{{
                  $t("menu.recharge_record")
                }}</span>
                <a-divider style="margin: 10px 0" />
                <a-row :gutter="[16, 16]">
                  <a-col>
                    <a-input
                      v-model:value="searchForm.payNo"
                      allow-clear
                      :placeholder="$t('finance.serial_numbers')"
                      style="width: 250px"
                    />
                  </a-col>
                  <a-col>
                    <a-select
                      v-model:value="searchForm.status"
                      :placeholder="$t('warehouse.status')"
                      allow-clear
                      style="width: 250px"
                    >
                      <a-select-option
                        v-for="(item, index) in statusList"
                        :key="item"
                        :title="$t($enumLangkey('rechargeStatus', item))"
                        :value="item"
                        >{{ $t($enumLangkey('rechargeStatus', item)) }}</a-select-option
                      >
                    </a-select>
                  </a-col>
                  <a-col>
                    <a-select
                      v-model:value="searchForm.rechargeType"
                      :placeholder="$t('finance.payment_method')"
                      allow-clear
                      style="width: 250px"
                    >
                      <a-select-option
                        v-for="(item, index) in rechargeTypeList"
                        :key="item"
                        :title="$t($enumLangkey('rechargeType', item))"
                        :value="item"
                        >{{ $t($enumLangkey('rechargeType', item)) }}</a-select-option
                      >
                    </a-select>
                  </a-col>
                  <a-col>
                    <a-select
                      v-model:value="searchForm.currencyId"
                      :placeholder="$t('finance.recharge_currency')"
                      optionFilterProp="search-key"
                      show-search
                      allow-clear
                      style="width: 250px"
                    >
                      <a-select-option
                        v-for="(item, index) in currencyList"
                        :key="index"
                        :title="getCnOrEnName(item)"
                        :value="item.id"
                        :search-key="item.cnName + item.enName + item.key"
                        >{{ getCnOrEnName(item) }}</a-select-option
                      >
                    </a-select>
                  </a-col>
                  <a-col>
                    <a-select
                      v-model:value="searchForm.payCurrencyId"
                      :placeholder="$t('finance.payment_currency')"
                      optionFilterProp="search-key"
                      show-search
                      allow-clear
                      style="width: 250px"
                    >
                      <a-select-option
                        v-for="(item, index) in currencyList"
                        :key="index"
                        :title="getCnOrEnName(item)"
                        :value="item.id"
                        :search-key="item.cnName + item.enName + item.key"
                        >{{ getCnOrEnName(item) }}</a-select-option
                      >
                    </a-select>
                  </a-col>
                  <a-col>
                    <a-button
                      type="primary"
                      :loading="tableData.loading"
                      @click="searchLogistics"
                      >{{ $t("common.query") }}</a-button
                    >
                  </a-col>
                </a-row>
              </template>
            </a-table>
          <!-- </a-tab-pane>
        </a-tabs> -->
      </div>
      <RechargeModal ref="rechargeModalRef" :currency-list="currencyList" @confirm="handleOpenNextModal" />
      <RechargeNextModal ref="rechargeNextModalRef" :currency-list="currencyList" @confirm="getTableList" />
    </template>
    <template #contentFooter>
      <c-pager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      />
    </template>
  </Content>
</template>
<script>
import { defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import {
  Tabs,
  Divider,
  Table,
  Button,
  Form,
  Select,
  Row,
  Col,
  Input,
  Tag,
  Dropdown,
  Menu,
  Modal,
  message,
} from "ant-design-vue";
import CPager from "@/views/components/CPager.vue";
import Content from "@/views/components/Content.vue";
import RechargeModal from './components/rechargeModal.vue'
import RechargeNextModal from './components/rechargeNextModal.vue'
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import {
  getRechargeList,
  cancelReChargeLog,
  printReChargeLog,
} from "@/api/modules/finance/balance";
import { getCurrencys } from "@/api/modules/common";
import {
  rechargeStatus as rechargeStatusEnum,
  rechargeType as rechargeTypeEnum,
} from "@/enum/enum.json";
import { getName } from '@/utils/general'
import localPrint from "@/utils/localPrint";

export default defineComponent({
  name: "finance_recharge_record",
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ADivider: Divider,
    ATable: Table,
    AButton: Button,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ATag: Tag,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    CPager,
    Content,
    RechargeModal,
    RechargeNextModal,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();
    const searchFormRef = ref(null);
    const rechargeModalRef = ref(null);
    const rechargeNextModalRef = ref(null);

    const columns = [
      {
        width: 150,
        title: () => vueI18n.t("logistics.time"),
        slots: {
          customRender: "creationTime",
        },
      },
      {
        dataIndex: "reChargeNo",
        width: 200,
        title: () => vueI18n.t("finance.recharge_serial_number"),
      },
      {
        dataIndex: "toCurrencyCode",
        width: 100,
        title: () => vueI18n.t("finance.recharge_currency"),
      },
      {
        width: 150,
        title: () => vueI18n.t("finance.recharge_amount"),
        slots: {
          customRender: "rechargeAmount",
        },
      },
      {
        dataIndex: "fromCurrencyCode",
        width: 100,
        title: () => vueI18n.t("finance.payment_currency"),
      },
      {
        dataIndex: "exchangeRate",
        width: 100,
        title: () => vueI18n.t("finance.exchange_rate"),
      },
      {
        width: 150,
        title: () => vueI18n.t("finance.payment_method"),
        slots: {
          customRender: "rechargeType",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("finance.payment_amount"),
        slots: {
          customRender: "payAmount",
        },
      },
      {
        dataIndex: "payNo",
        width: 150,
        title: () => vueI18n.t("finance.payment_serial_number"),
      },
      {
        width: 100,
        title: () => vueI18n.t("finance.recharge_status"),
        slots: {
          customRender: "status",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("finance.remarks"),
        slots: {
          customRender: "remark"
        }
      },
      {
        width: 120,
        fixed: "right",
        title: () => vueI18n.t("common.operation"),
        slots: {
          customRender: "operation"
        }
      },
    ];

    const state = reactive({
      tabList: [],
      activeTabKey: null,
      rechargeBtnLoading: false,
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      searchForm: {
        payNo: null,
        status: null,
        rechargeType: null,
        currencyId: null,
        payCurrencyId: null,
      },
      searchFormCache: {},
      statusList: [],
      rechargeTypeList: [],
      currencyList: [],
      operationLoading:false,
    });

    // tab初始化
    for (const key in rechargeTypeEnum) {
      let val = rechargeTypeEnum[key];
      state.tabList.push({
        key: val,
        value: () => vueI18n.t("enum.recharge_type_" + val),
      });
    }
    if (state.tabList.length > 0) {
      state.activeTabKey = state.tabList[0].key;
    }

    // 状态列表初始值
    state.statusList = Object.values(rechargeStatusEnum)

    // 付款方式列表初始值
    state.rechargeTypeList = Object.values(rechargeTypeEnum)

    const getTableList = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchFormCache
      );
      getRechargeList(data)
        .then((res) => {
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items.map(item => {
            item.loading = false
            return item
          });
        })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getTableList();
    };

    const searchLogistics = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      Object.assign(state.searchFormCache, state.searchForm);
      getTableList();
    };

    // 获取币种列表
    const getCurrencysList = () => {
      getCurrencys({ isActive: true }).then((res) => {
        let { result } = res;
        if (result) {
          let currencyList = result.map((item) => {
            return {
              id: item.id,
              key: item.code,
              symbol: item.symbol,
              value: '',
              cnName: item.cnName,
              enName: item.enName,
            };
          });
          state.currencyList = currencyList;
        }
      });
    };

    const getCnOrEnName = (item) => {
      return getName(item, getters.language)
    }

    const getTextColor = (status) => {
      let color = ''
      switch (status) {
        case rechargeStatusEnum.success:
          color = 'text-success'
          break;
        case rechargeStatusEnum.fail:
          color = 'text-error'
          break;
        case rechargeStatusEnum.waitSubmit:
          color = 'text-primary'
          break;
        case rechargeStatusEnum.canceled:
          color = 'color-canceled'
          break;
        default:
          break;
      }
      return color
    }

    const handlePrint = async (record) => {
      try {
        record.loading = true
        let url = printReChargeLog({ id: record.id, culture: getters.language.toLowerCase() })
        await localPrint(url, "pdf")
      } catch (error) {
        
      } finally {
        record.loading = false
      }
    }

    const handleCancel = (id) => {
      Modal.confirm({
        title: vueI18n.t("common.operation"),
        content: vueI18n.t("common.are_you_sure_cancel"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          return cancelReChargeLog({ id }).then(() => {
            message.success(vueI18n.t("common.successfully_canceled"));
            getTableList();
          });
        },
      });
    }

    const handleMenuClick = (e) => {
      let { key, item } = e
      let { record } = item
      switch (key) {
        case '1':
          rechargeNextModalRef.value.handleOpen(record.id)
          break;
        case '2':
          handlePrint(record)
          break;
        case '3':
          handleCancel(record.id)
          break;
        default:
          break;
      }
    }

    const handleShowModal = () => {
      rechargeModalRef.value.handleOpen()
    }

    const handleOpenNextModal = (id) => {
      getTableList()
      rechargeNextModalRef.value.handleOpen(id)
    }

    onMounted(() => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getTableList();
      getCurrencysList();
    });

    return {
      ...toRefs(state),
      columns,
      searchFormRef,
      rechargeModalRef,
      rechargeNextModalRef,
      rechargeStatusEnum,
      getTableList,
      handlePage,
      searchLogistics,
      handleShowModal,
      getCnOrEnName,
      getTextColor,
      handleMenuClick,
      handleOpenNextModal,
    };
  },
});
</script>

<style scoped>
.color-canceled {
  color: #BBBBBB;
}
</style>